import React from 'react'
import useDeviceType from '@/hooks/useDeviceType'
import styles from './NeedHelp.module.scss'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { open as OpenChat } from '@saatva-bits/pattern-library.utils.chat'
import { phoneNumbers } from '@/constants/contact-info'
import classNames from 'classnames'

const NeedHelp = ({ className }) => {
    const { isMobile, isTablet } = useDeviceType('mobile')
    const isTabletOrMobile = isTablet || isMobile

    const layoutClass = classNames(styles.container, className, {
        [styles.columnLayout]: isTabletOrMobile,
    })

    return (
        <div className={layoutClass}>
            {!isTabletOrMobile && (
                <div className={styles.iconContainer}>
                    <SvgSprite className={styles.icon} spriteID='icon-headphones' />
                </div>
            )}
            <div className={styles.textContainer} data-attribute='needHelpComponent'>
                <p className={styles.title}>
                    {isMobile ? (
                        <>
                            Questions?
                            <br />
                            We're always here to help.
                        </>
                    ) : (
                        'Questions? We’re always here to help.'
                    )}
                </p>
                <p className={styles.contactInfo}>
                    <a href={`tel:${phoneNumbers.supportPhoneNumber}`} data-attribute='phoneNeedHelpLink'>Call {phoneNumbers.supportPhoneNumber}</a>
                    <span className={styles.dot}>•</span>
                    <a className={styles.chatLink} onClick={OpenChat} data-attribute='chatNeedHelpLink'>Chat with us</a>
                </p>
            </div>
        </div>
    )
}

export default NeedHelp
