import { SelectionProvider } from '@saatva-bits/pattern-library.modules.selection'
import { FaqProvider } from '@saatva-bits/pattern-library.modules.faq'

import Layout from '@/layouts/Default'
import Buystack from '@/components/Buystack'
import BundlesBuystack from '@/components/BundlesBuystack'
import ProductStickyNavBar from '@/components/ProductStickyNav/ProductStickyNavBar'
import { linkConfig } from '@/components/ProductStickyNav/NavLinkConfig'
import VideoBelowPDPCarousel from '@/components/VideoBelowPDPCarousel'

import { AddonProvider } from '@/contexts/addon'
import Reviews from '@/contexts/reviews'
import Recommendations from '@/contexts/recommendations'
import Global from '@/contexts/global'
import { MATTRESSES } from '@/constants/product-collections'
import {
    CLASSIC_STARTER_BUNDLE,
    SATEEN_SHEETS_LATEX_PILLOW,
    SATEEN_SHEETS_LATEX_PILLOW_PAD,
    SATEEN_SHEETS_MEMORY_FOAM_PILLOW,
} from '@/constants/product-codes'
import { ContentThemeProvider } from '@/contexts/contentTheme'
import Content from './content'
import NeedHelpSection from '../../components/NeedHelpSection'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/hooks'
import CarouselPanel from '@/components/CarouselPanel'
import { LearnMoreModalProvider } from '@/contexts/learnMoreModal'
import AddToFavoritesPanelWrapper from '@/components/AddToFavoritesPanel/AddToFavoritesPanelWrapper.jsx'

export default function PDPView(props) {
    // We need to avoid using hooks that can change after load in this component.
    // Those values will re-initialize the selection context store, which can cause issues
    // such as in DEF-27800 and EX-437.
    const { isV1: phoneNumberTest } = useExperiment('EXP.NEED_HELP.EX-391')

    const {
        products,
        productCode,
        selectionContextProductOverrides,
        faqsAndSpecs,
        reviewsData,
        crossSellSkus,
        contentThemeContextData
    } = props

    const { questionCount, reviewCount, totalProductReviewCount } = reviewsData
    const hasStickyNavBar = MATTRESSES.includes(productCode)
    const hideFromDesktopClasses = 'u-hidden--lg-up'
    const bundleProducts = [CLASSIC_STARTER_BUNDLE, SATEEN_SHEETS_LATEX_PILLOW, SATEEN_SHEETS_LATEX_PILLOW_PAD, SATEEN_SHEETS_MEMORY_FOAM_PILLOW]

    return (
        <Layout {...props}>
            <Global.Provider urlProductCode={productCode}>
                <SelectionProvider productsData={products} productOverrides={selectionContextProductOverrides}>
                    <ContentThemeProvider contextData={contentThemeContextData}>
                        <FaqProvider faqList={faqsAndSpecs?.faqList} specList={faqsAndSpecs?.specList}>
                            <Reviews.Provider
                                questionCount={questionCount}
                                reviewCount={reviewCount}
                                totalProductReviewCount={totalProductReviewCount}
                            >
                                <AddonProvider>
                                    <Recommendations.Provider
                                        crossSellSkus={crossSellSkus}
                                    >
                                        <LearnMoreModalProvider> {/* This is for the test EXP.COMFORT_QUIZ.EX-220 */}
                                            <section id='productPanel' className='section u-paddingTop--none'>
                                                {hasStickyNavBar && <ProductStickyNavBar links={linkConfig[productCode]} />}
                                                <div className='container'>
                                                    <div className='row'>
                                                        <CarouselPanel productCode={productCode} />
                                                        <div className='col col--xs-12 col--md-12 col--lg-5 productPanelWrapper__rightPanel'>
                                                            {bundleProducts.includes(productCode) ? <BundlesBuystack productCode={productCode} /> : <Buystack />}
                                                            <VideoBelowPDPCarousel productCode={productCode} className={hideFromDesktopClasses} />
                                                            {phoneNumberTest && <NeedHelpSection className={hideFromDesktopClasses} />}
                                                            <AddToFavoritesPanelWrapper />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <Content productCode={productCode} />
                                        </LearnMoreModalProvider>
                                    </Recommendations.Provider>
                                </AddonProvider>
                            </Reviews.Provider>
                        </FaqProvider>
                    </ContentThemeProvider>
                </SelectionProvider>
            </Global.Provider>
        </Layout>
    )
}
